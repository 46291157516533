<template>
  <div>
    <portal to="subheader-left">
      <div class="d-flex flex-wrap flex-sm-nowrap align-items-center">
        <h5 class="text-dark font-weight-bold mr-5 mt-2">Prioritäten</h5>
        <div class="subheader-separator subheader-separator-ver mr-5 bg-gray-200"></div>
        <div v-if="loadingButtonWasPressed" class="d-flex flex-wrap flex-sm-nowrap align-items-center">
          <CustomMultiselect
            class="mt-2 mr-3"
            style="width: 250px; height: 100%"
            v-model="reisekuerzelSearch"
            placeholder="Reisekürzel"
            :loading="isBusy"
            :options="reisekuerzel"
            :multiple="true"
          />
          <CustomMultiselect
            class="mt-2 mr-3"
            v-model="regionFilter"
            style="width: 250px"
            placeholder="Region"
            :loading="isBusy"
            :options="regions"
            :multiple="true"
          />
          <b-form-checkbox
            class="mr-3"
            v-model="isFilterDifferencePrioritaetEnabled"
            switch
            :disabled="isBusy"
            >Nur Änderungen anzeigen</b-form-checkbox
          >
        </div>
      </div>
    </portal>
    <portal to="subheader-right">
      <b-button
        v-if="loadingButtonWasPressed"
        @click="onApplyReisePriorites"
        variant="primary"
        size="sm"
        :disabled="isBusy"
        class="mr-10"
        >Vorschläge übernehmen</b-button
      ></portal
    >
    <div class="card card-custom gutter-b pb-0 mb-0" :style="tableHeight">
      <div
        v-if="!loadingButtonWasPressed"
        class="card-body p-5 d-flex align-items-center justify-content-center"
      >
        <b-button size="lg" variant="primary" @click="clickLoadPrioritiesButton">Lade Prioritäten</b-button>
      </div>
      <div v-else class="card-body p-5 d-flex flex-column">
        <b-table
          v-if="!isBusy"
          hover
          show-empty
          :busy.sync="isBusy"
          :items="paginatedTableData"
          :fields="fields"
          :filter="reisekuerzelSearch"
          :sticky-header="headerHeightInPx"
          :empty-filtered-text="isBusy ? 'Laden...' : $t('COMMON.EMPTYFILTEREDTEXT', { name: 'Vorgänge' })"
          :empty-text="isBusy ? 'Laden...' : $t('COMMON.EMPTYTEXT', { name: 'Vorgänge' })"
        >
          <template #cell(calculatedPrioritaet)="{ item }">
            <b-badge :variant="getVariantByPriority(item.calculatedPrioritaet)">
              {{ getValueByPriority(item.calculatedPrioritaet) }}
            </b-badge>
          </template>
          <template #cell(oldPrioritaet)="{ item }">
            <b-badge :variant="getVariantByPriority(item.oldPrioritaet)">
              {{ getValueByPriority(item.oldPrioritaet) }}
            </b-badge>
          </template>
        </b-table>

        <b-skeleton-table
          v-else
          :rows="rowsSkeletonTable"
          :columns="fields.length"
          :sticky-header="headerHeightInPx"
        ></b-skeleton-table>

        <SKRPagination
          v-if="!isBusy"
          class="mt-auto mb-2 ml-2"
          v-model="currentPage"
          :isLoading="isBusy"
          :totalRows="itemCount"
          :currentRows="paginatedTableData.length || 0"
          @pageChange="onPageChange"
        ></SKRPagination>
        <b-skeleton v-else height="35px" class="mt-auto" width="25%"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_REISEN_PRIORITY_DATA,
  APPLY_REISEN_PRIORITY_DATA,
} from '@/core/produkte/reisen/stores/reisen.module';
import SKRPagination from '@/components/common/skr-pagination.vue';
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
import configPirority from '@/core/produkte/reisen/priority.config.json';

import { mapState } from 'vuex';

export default {
  components: {
    SKRPagination,
    CustomMultiselect,
  },
  name: 'PriorityOverview',
  props: {},
  data() {
    return {
      loadingButtonWasPressed: false,
      regionFilter: [],
      reisekuerzelSearch: [],
      isFilterDifferencePrioritaetEnabled: false,
      skip: 0,
      top: 25,
      currentPage: 1,
      fields: [
        { key: 'regionName', label: 'Region', sortable: true },
        { key: 'travelCode', label: 'Reisekürzel', sortable: true },
        {
          key: 'umsatz',
          label: 'Umsatz',
          sortable: true,
          formatter: value => {
            return this.$options.filters.currency(value) || '-';
          },
        },
        {
          key: 'oldPrioritaet',
          label: 'Alte Priorität',
          sortable: true,
          formatter: value => {
            return configPirority[value].value || '-';
          },
        },
        {
          key: 'calculatedPrioritaet',
          label: 'Berechnete Priorität',
          sortable: true,
          formatter: value => {
            return configPirority[value].value || '-';
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      reisenPriorityData: state => state.reisen.reisenPriorityData,
      isBusy: state => state.reisen.isBusy,
    }),
    tableHeight: function () {
      const viewHeight = window.innerHeight - 45;
      const navbarHeight = 65;
      const subNavbarHeight = 52;
      const calculatedTableHeight = viewHeight - navbarHeight - subNavbarHeight;
      return 'height: ' + calculatedTableHeight + 'px';
    },
    headerHeight: function () {
      const viewHeight = window.innerHeight - 130;
      const navbarHeight = 65;
      const subNavbarHeight = 52;
      const calculatedTableHeight = viewHeight - navbarHeight - subNavbarHeight;
      return calculatedTableHeight;
    },
    headerHeightInPx: function () {
      return this.headerHeight + 'px';
    },
    reisekuerzel() {
      if (!this.reisenPriorityData) {
        return [];
      }
      return Object.values(this.reisenPriorityData.priorityOverviewsByRegion).flatMap(value =>
        Object.keys(value.prioritiesByReisekuerzel)
      );
    },
    regions() {
      return !this.reisenPriorityData ? [] : Object.keys(this.reisenPriorityData.priorityOverviewsByRegion);
    },
    itemCount() {
      if (!this.reisenPriorityData) {
        return 0;
      }
      const totalItemLength = Object.values(this.reisenPriorityData.priorityOverviewsByRegion).reduce(
        (accumulator, value) => accumulator + Object.keys(value.prioritiesByReisekuerzel).length,
        0
      );
      const filteredItemLength = this.filteredTableData.length;
      return filteredItemLength < totalItemLength ? filteredItemLength : totalItemLength;
    },
    paginatedTableData() {
      let filteredData = this.filteredTableData;

      const startIndex = (this.currentPage - 1) * this.top;
      const endIndex = startIndex + this.top;

      const slicedData = filteredData.slice(startIndex, endIndex);

      return slicedData.length > 0 ? slicedData : [];
    },
    filteredTableData() {
      let filteredData = this.tableData;

      if (this.reisekuerzelSearch.length > 0) {
        filteredData = filteredData.filter(item =>
          this.reisekuerzelSearch.some(search =>
            item.travelCode.toLowerCase().startsWith(search.toLowerCase())
          )
        );
      }

      if (this.regionFilter.length > 0) {
        filteredData = filteredData.filter(item => this.regionFilter.includes(item.regionName));
      }

      if (this.isFilterDifferencePrioritaetEnabled) {
        filteredData = filteredData.filter(item => item.priorityChange !== 0);
      }
      return filteredData.length > 0 ? filteredData : [];
    },
    tableData() {
      if (!this.reisenPriorityData) return [];
      const priorityOverviews = this.reisenPriorityData.priorityOverviewsByRegion;
      const tableData = [];

      for (const regionName in priorityOverviews) {
        const region = priorityOverviews[regionName];
        const prioritiesByReisekuerzel = region.prioritiesByReisekuerzel;

        for (const travelCode in prioritiesByReisekuerzel) {
          const travelCodeData = prioritiesByReisekuerzel[travelCode];

          tableData.push({
            regionName: regionName,
            travelCode: travelCode,
            priorityChange: travelCodeData.calculatedPrioritaet - travelCodeData.oldPrioritaet,
            umsatz: travelCodeData.umsatz,
            oldPrioritaet: travelCodeData.oldPrioritaet,
            calculatedPrioritaet: travelCodeData.calculatedPrioritaet,
          });
        }
      }
      return tableData;
    },
    rowsSkeletonTable() {
      const skeletonHeaderHeight = 40.75;
      const skeletonRowHeight = 36.75;

      const skeletonRows = Math.floor((this.headerHeight - skeletonHeaderHeight) / skeletonRowHeight) - 1;
      return skeletonRows;
    },
  },
  watch: {
    itemCount() {
      this.currentPage = 1;
      this.skip = 0;
    },
  },
  methods: {
    async onApplyReisePriorites($event) {
      const msgBoxConfirm = await this.$bvModal.msgBoxConfirm(
        'Sind sie sicher, dass Sie die Prioritäten für alle Reisen übernehmen wollen?',
        {
          centered: true,
          okTitle: 'Übernehmen',
          cancelTitle: 'Abbrechen',
        }
      );

      if (msgBoxConfirm) {
        this.$store.dispatch(APPLY_REISEN_PRIORITY_DATA).then(() => {
          this.toast('Prioritäten erfolgreich übernommen');
        });
      }
    },
    getVariantByPriority(priority) {
      return configPirority[priority].color;
    },
    getValueByPriority(priority) {
      return configPirority[priority].value;
    },
    clickLoadPrioritiesButton() {
      this.loadingButtonWasPressed = true;
      this.$store.dispatch(GET_REISEN_PRIORITY_DATA);
    },
    onPageChange(ctx) {
      this.currentPage = ctx.page;
      this.top = ctx.top;
      this.skip = ctx.skip;
    },
  },
};
</script>
